import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Session2 from "../../images/design-thinking-2.jpg"
import Session3 from "../../images/design-thinking-3.jpg"

export default function DesignThinking() {
    const data = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "design-thinking.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      two: file(relativePath: { eq: "design-thinking-2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
    <SEO title="design thinking and human centered design" />
    <div className="c12 title main">
        <h1>Design Workshops & Consulting</h1>
    </div>
    <div className="c12 main">
        <div className="summary">
            <div className="summary-section">
                <div className="section summary-item">
                    <h5 className="subtitle">Overview</h5>
                    <p>Leading Design Thinking & Human Centered Design workshops at non-profit organizations as well as at insurtech startup, Lemonade. I also provided design thinking consulting for Lemonade.</p>
                </div>

                <div className="section summary-item">
                    <h5 className="subtitle">Project Type</h5>
                    <p>Voluntary, outside of work</p>
                </div>

                <div className="section summary-item">
                    <h5 className="subtitle">Roles</h5>
                    <p>Facilitator</p>
                </div>
                <div className="section summary-item">
                    <h5 className="subtitle">Timeline</h5>
                    <p>Ongoing since 2016</p>
                </div>
            </div>
            <Img fluid={data.one.childImageSharp.fluid} className="summary-section" style={{ width: "100%" }}/>
        </div>
    </div>
    <div className="grid">
        <div className="c6 divider"></div>
        <div className="c6">
            <div className="section">
                <h5 className="subtitle">Workshops</h5>
                <h2 className="workshop-title">Human Centered Design</h2>
                <p>During my year-long fellowship with <a target="_blank" href="https://www.jdc.org/" className="workshop-link">JDC</a>, I offered several workshops to the staff from a variety of departments.</p>
                <br/>
                <p>The participants are each directly working on innovative initiatives to help employ populations in Israel including Arab-Israelis, youth at risk, people with disabilities, Ultra-Orthodox, and others.</p>
                <br/>
                <Img fluid={data.two.childImageSharp.fluid} className="summary-section" style={{ width: "100%" }}/>
                <br/>
                <h2 className="workshop-title">Design Thinking</h2>
                <p>Lemonade had two internal hackathons and at each, employees voted on the top two employee-led workshop sessions. My design thinking workshop was chosen both times.</p>
                <br/>
                <img src={Session3} className="img"/>
                <p>Here's an example of the 30 min presentation that I gave during the hackathon in 2018:</p>
                <br/>
                <iframe className="design-slides" src="https://docs.google.com/presentation/d/e/2PACX-1vS8U4iUw8GVwFcARdCW8ehVyToKrj0f21QwnEtR4sO2nLdVIlbJA_VNngD1pimweQ/embed?start=false&loop=true&delayms=3000" frameBorder="0" allowFullScreen={true} mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </div>
        </div>
        <div className="c6">
            <div className="section">
                <h5 className="subtitle">Consulting</h5>
                <h2 className="workshop-title">Lemonade</h2>
                <p>I provided Design Thinking strategies and tools and crafted frameworks for many creative meetings.  Examples of my work with Lemonade's Social Strategy Lead, Silvi, can be found in this <a target="_blank" href="https://www.lemonade.com/blog/covered-by-lemonade/" className="workshop-link">blog post</a>.</p>
                <br/>
            </div>
        </div>
    </div>
    <div className="bottom-nav">
        <Link to="/portfolio/what-the-tech">What the Tech</Link>
        <Link to="/portfolio/hackathon">Hack for Olim Hackathon</Link>
        <Link to="/portfolio/kfitzah">Kfitzah</Link>
        <Link to="/portfolio/art">Art</Link>
        <Link to="/">Home</Link>
    </div>
  </Layout>
    )
}